<template>
	<div>
		<q-list v-for="noticeboard in noticeboards" :key="noticeboard.id">
			<Noticeboard
				class="list-element"
				:noticeboard='noticeboard'
			/>
		</q-list>
	</div>
</template>

<script>
import Noticeboard from '@/components/scenarios/audio_noticeboard/Noticeboard.vue';
export default {
	name:"NoticeboardList",
	components:{
		Noticeboard
	},
	props:{
		noticeboards: Array,
	},
}
</script>
<style lang="scss" scoped>
	.list-element {
		padding: 0.5rem;
		margin-bottom: 0.5rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid $grey-4;
		background: $grey-1;
		font-size: 1rem;
		flex-direction: row;
		text-align: left;
		border-radius: 4px;
		&:hover {
			background-color: $grey-2;
		}
	}
</style>