<template>
	<div :class="$q.screen.gt.xs ? 'q-pa-xl' : 'q-pa-sm'">
		<h1 class="text-h5 q-pb-md q-my-md text-left fields-tab-header">
			Audio Noticeboard
		</h1>
		<div class="margin-top">
			<SuccessErrorMessage :message="message" />
		</div>
		<NoticeboardList
			v-if="noticeboards_ready"
			:noticeboards='available_created_noticeboard'
		/>
		<div
			v-if="(!is_sender && available_noticeboard_numbers)"
			style="
				position: sticky;
				bottom: 20px;
				width: fit-content;
				margin-left: auto;
				margin-right: auto;
			"
			class="q-mt-xl"
		>
			<q-btn
				v-if="$q.screen.gt.sm"
				rounded
				icon-right="add"
				padding="md lg"
				label="Create"
				color="primary"
				:to="{ name: 'Create Audio Noticeboard'}"
				@click.native="updateNoticeboard()"
			/>
			<q-btn
				v-else
				round
				icon="add"
				color="primary"
				size="lg"
				:to="{ name: 'Create Audio Noticeboard'}"
				@click.native="updateNoticeboard()"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Create Audio Noticeboard
				</q-tooltip>
			</q-btn>
		</div>
	</div>
</template>
<script>
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import NoticeboardList from '@/components/scenarios/audio_noticeboard/NoticeboardList.vue';
import MessageFormAPI from '@/services/api/MessageForm.js';
import { mapActions, mapGetters } from 'vuex';

export default {
	name: "AudioNoticeboard",
	meta: {
		title: 'Alert Cascade - Audio Noticeboard'
	},
	components: {
		SuccessErrorMessage,
		NoticeboardList,
		MessageFormAPI,
	},
	data() {
		return {
			available_created_noticeboard: [],
			available_noticeboard_numbers: false,
			noticeboards_ready: false,
			message: {}
		};
	},
	methods: {
		...mapActions(['updateNoticeboardEdit', 'set_active_item']),
		updateNoticeboard() {
			this.updateNoticeboardEdit(false);
			this.set_active_item(203);
		}
	},
	computed: {
		...mapGetters(['current_CG', 'logged_user']),
		is_sender: function(){
			return this.logged_user.customer_permission == "SENDER"
		}
	},
	async created() {
		try {
			this.available_created_noticeboard = await MessageFormAPI.listAudioNoticeboards();
		} catch (error) {
			this.message = {
				'type': 'error',
				'text': error.data
			};
		}
		let noticeboard_numbers = JSON.parse(this.current_CG.customer_group.settings).noticeboard_numbers;
		if (this.available_created_noticeboard.length == noticeboard_numbers.length) {
			this.available_noticeboard_numbers = false;
		} else {
			this.available_noticeboard_numbers = true;
		}
	},
	watch: {
		available_created_noticeboard: {
			deep: true,
			handler() {
				this.noticeboards_ready = !!Object.keys(this.available_created_noticeboard).length;
			}
		}
	},

}
</script>
