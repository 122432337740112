<template>
	<q-item  class="full-width">
		<div>
			<q-btn
				flat
				round
				icon="o_remove_red_eye"
				color="dark"
				@click="(show_preview_noticeboard=true)"
			>
				<q-tooltip anchor="top middle" :delay="500">
					Preview
				</q-tooltip>
			</q-btn>
			{{noticeboard.name}} {{noticeboard.phone_number.number}}
		</div>
		<q-btn
			flat
			round
			icon="o_edit"
			color="dark"
			:to="{ name: 'Update Audio Noticeboard', params: {id:noticeboard.id} }"
			@click.native="set_active_item(203)"
		>
			<q-tooltip anchor="top middle" :delay="500">
				Edit
			</q-tooltip>
		</q-btn>
		<q-dialog v-model="show_preview_noticeboard">
			<NoticeboardPreview
				:noticeboard="noticeboard"
			/>
		</q-dialog>
	</q-item>
</template>
<script>
import { mapActions } from 'vuex';
import NoticeboardPreview from '@/components/scenarios/audio_noticeboard/NoticeboardPreview.vue';

export default {
	name:"Noticeboard",
	components:{
		NoticeboardPreview
	},
	props:{
		noticeboard: Object,
	},
	data(){
		return{
			show_preview_noticeboard: false,
		}
	},
	methods:{
		...mapActions(['set_active_item'])
	}
}
</script>
<style lang="scss" scoped>
.data-wrapper {
	text-align: left;
	border-radius: 0.375rem;
	width: 100%;
	background: $grey-1;
	border: 1px solid $grey-4;
	font-size: 1rem;
}
</style>