<template>
</template>

<script>
export default {
	name: 'SuccessErrorMessage',
	props: {
		message: Object
	},
	watch:{
		message: function(){
			if (this.message.type == 'error') {
				this.$q.notify({
					timeout: 6700,
					message: this.message.text,
					actions: [
						{
							label: 'Dismiss',
							color: 'primary',
						},
					],
				})
			}
			if (this.message.type == 'success') {
				this.$q.notify(this.message.text)
			}
		}
	}
}
</script>