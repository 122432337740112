import { render, staticRenderFns } from "./Noticeboard.vue?vue&type=template&id=d05ca0a6&scoped=true&"
import script from "./Noticeboard.vue?vue&type=script&lang=js&"
export * from "./Noticeboard.vue?vue&type=script&lang=js&"
import style0 from "./Noticeboard.vue?vue&type=style&index=0&id=d05ca0a6&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d05ca0a6",
  null
  
)

export default component.exports
import QItem from 'quasar/src/components/item/QItem.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QItem,QBtn,QTooltip,QDialog});
