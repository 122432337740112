<template>
	<q-card style="width: 100%;" class="q-pa-sm no-padding">
		<SuccessErrorMessage :message="message" />
		<q-card-section>
			<div class="row border-bottom q-pa-sm">
				<div class="col-6"><b>Noticeboard name:</b></div>
				<div class="col-6">{{noticeboard.name}}</div>
			</div>
			<div class="row border-bottom q-pa-sm">
				<div class="col-6"><b>Noticeboard number:</b> </div>
				<div class="col-6">{{noticeboard.phone_number.number}}</div>
			</div>
			<div class="row border-bottom q-pa-sm">
				<div class="col-6"><b>Noticeboard message:</b></div>
				<div class="col-6">{{noticeboard.message}}</div>
			</div>
			<div class="row border-bottom q-pa-sm">
				<div class="col-6"><b>Noticeboard voice:</b></div>
				<div class="col-6">{{tts_voice}}</div>
			</div>
			<div class="q-py-sm">
				<preview-button
					class="no-margin"
					:loading="show_machine_spinner"
					:is_disabled='noticeboard.message'
					@playTTS=playTTS
				/>
			</div>
		</q-card-section>
		<q-card-actions class="justify-end">
			<q-btn flat label="Close" color="primary" v-close-popup/>
		</q-card-actions>
	</q-card>
</template>
<script>
import MessageFormAPI from '@/services/api/MessageForm.js';
import PreviewButton from '@/components/datasets/utils/PreviewButton.vue'
import SuccessErrorMessage from "@/components/scenarios/calendar_reports/SuccessErrorMessage.vue";
import message_forms_utils from '@/utils/message_forms.js';
import { mapGetters } from 'vuex';
export default {
	name: 'NoticeboardPreview',
	props:{
		noticeboard: Object,
	},
	components: {
		SuccessErrorMessage,
		PreviewButton
	},
	data() {
		return {
			message: {},
			show_machine_spinner: false,
			noticeboard_settings: {},
			people_options: [],
			tts_voice: '',
		}
	},
	methods: {
		...mapGetters(['people']),
		async playTTS() {
			let message = {};
			this.message = {};
			message = { 'message': this.noticeboard.message, 'tts_voice': this.noticeboard_settings.tts_voice };
			this.show_machine_spinner = true;
			const response = await MessageFormAPI.getTTSAudio(message);
			this.show_machine_spinner = false;
			let audio = new Audio(response);
			audio.play();
		}
	},
	created(){
		this.noticeboard_settings =  JSON.parse(this.noticeboard.settings)
		this.people_options = this.people.length ? this.people_options = this.people : message_forms_utils.default_people();
		this.tts_voice = this.people_options.filter(item => item.name == this.noticeboard_settings.tts_voice)[0].value
	}
}
</script>
<style>
	.no-padding .q-card__section{
		padding-bottom: 0px!important;
	}
</style>
<style lang="scss" scoped>
	.border-bottom{
		border-bottom: 3px double $grey-5;
	}
	.no-margin{
		margin-top: 0px!important;
	}
</style>
